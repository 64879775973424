<template>

    <el-alert
        v-if="!getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)"
        :title="$t('noCurrentSchoolyearWarning')"
        type="warning"
        class="mb-5"/>

    <el-row :gutter="40" class="panel-group mb-10" v-if="getSchoolData(SchoolData.NAME.NAME)">
        <el-col :xs="48" :sm="48" :lg="24" class="card-panel-col">
            <el-card class="card-panel" shadow="hover">
                <template #header>
                    <div class="card-header">
                        <b style="font-size: 21px">{{ getSchoolData(SchoolData.NAME.NAME) }}</b>
                    </div>
                </template>
                <div>
                    <el-descriptions
                        class="margin-top"
                        :column="3"
                        border>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.PHONE.NAME)">
                            <template #label>
                                <el-icon><Phone/></el-icon>
                                {{ $t('phone') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.PHONE.NAME) }}</small>
                        </el-descriptions-item>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.EMAIL.NAME)">
                            <template #label>
                                <el-icon><Message/></el-icon>
                                {{ $t('email') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.EMAIL.NAME) }}</small>
                        </el-descriptions-item>

                        <el-descriptions-item v-if="getSchoolData(SchoolData.ADDRESS.NAME)">
                            <template #label>
                                <el-icon><Location/></el-icon>
                                {{ $t('address') }}
                            </template>
                            <small>{{ getSchoolData(SchoolData.ADDRESS.NAME) }}</small>
                        </el-descriptions-item>

                    </el-descriptions>
                </div>
            </el-card>
        </el-col>
    </el-row>

    <el-card shadow="never" class="section-card">

        <template #header>
            <b>{{ $t('urCoursesList') }}</b>
        </template>

        <el-row :gutter="10">
            <el-col :span="8" v-for="(item, i) in teacherCourses" :key="i">
                <el-card shadow="hover" class="class-card">
                    <template #header>
                        <small><b>{{ getClassName(item.class) }}</b></small>
                    </template>
                    <div class="text-left">
                        <el-row :gutter="10">
                            <el-col :span="2" class="text-center">
                                <font-awesome-icon icon="chalkboard" class="class-card-icon"/>
                            </el-col>
                            <el-col :span="22">
                                <small>{{ getCourseName(item.course) }}</small>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <div class="text-left">
                        <el-row :gutter="10">
                            <el-col :span="2" class="text-center">
                                <font-awesome-icon icon="users" class="class-card-icon"/>
                            </el-col>
                            <el-col :span="22">
                                <small>{{ countClassStudents(item.class) + ' ' + $t('students') }}</small>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <div class="text-left">
                        <el-row :gutter="10">
                            <el-col :span="2" class="text-center">
                                <font-awesome-icon icon="user-tie" class="class-card-icon"/>
                            </el-col>
                            <el-col :span="22">
                                <small>
                                    <b>{{ $t('incumbent') }}</b>
                                    {{ getTeacherName( classes.find(c => c.id == item.class)?.incumbent ) }}
                                </small>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <div class="text-right">
                        <el-button size="small" type="warning" icon="right" @click="viewClassList(item.class, item.course)">
                            {{ $t('view') }}
                        </el-button>
                    </div>
                </el-card>
            </el-col>
        </el-row>

    </el-card>

    <el-card shadow="never" class="mt-10 section-card" v-if="classesHolded.length">

        <template #header>
            <b>{{ $t('classesYouHold') }}</b>
        </template>

        <el-row :gutter="10">
            <el-col :span="8" v-for="(item, i) in classesHolded" :key="i">
                <el-card shadow="hover" class="class-card">
                    <template #header>
                        <small><b>{{ getClassName(item.id) }}</b></small>
                    </template>
                    <div class="text-left">
                        <el-row :gutter="10">
                            <el-col :span="2" class="text-center">
                                <font-awesome-icon icon="users" class="class-card-icon"/>
                            </el-col>
                            <el-col :span="22">
                                <small>{{ countClassStudents(item.id) + ' ' + $t('students') }}</small>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <div class="text-left">
                        <el-row :gutter="10">
                            <el-col :span="2" class="text-center">
                                <font-awesome-icon icon="clipboard-list" class="class-card-icon"/>
                            </el-col>
                            <el-col :span="22">
                                <small>{{ this.repartitionList.filter(r => r.class == item.id).length + ' ' + $t('courses') }}</small>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <div class="text-right">
                        <el-button size="small" type="warning" icon="right" @click="viewClassList(item.id, null, true)">
                            {{ $t('view') }}
                        </el-button>
                    </div>
                </el-card>
            </el-col>
        </el-row>

    </el-card>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { Settings, SchoolData, Routes } from '@/utils/enums'

import accessManager from '@/mixins/access-manager'
import currentData from '@/mixins/current-data'

export default {
    name: 'Dashboard',
    mixins: [accessManager, currentData],
    data() {
        return {
            teacherCourses: [],
            SchoolData,
            Settings,
            Routes
        }
    },
    created() {

        this.fetchData()

    },
    computed: {
        ...mapState({
            userData: state => state.session.userData,
            schoolyears: state => state.schoolyears.schoolyears
        }),
        ...mapGetters({
            getSettingValue: 'settings/getSettingValue',
            getSchoolData: 'schooldata/getSchoolDataValue'
        }),
        classesHolded(){

            return this.classes.filter(c => c.incumbent == this.userData.roleData.id)

        }
    },
    methods: {
        ...mapActions({
            fetchRepartitionDB: 'repartition/fetchRepartitionDB'
        }),
        async fetchData() {

            let resp = await this.fetchRepartitionDB({
                action: 'getTeacherCourses',
                params: {
                    teacher: this.userData.roleData.id || 0,
                    schoolyear: this.currentSchoolyear
                }
            })

            if (resp.success && resp.data) this.teacherCourses = resp.data
            else this.$message.error(this.$t('anErrorOccured'))

        },
        countClassStudents(classId){

            return this.students.filter(student => student.class == classId).length

        },
        viewClassList(classId, courseId, incumbent = false){

            if (incumbent) {

                this.$router.push({
                    name: 'Incumbent' + Routes.TEACHER_CLASS_LIST.NAME,
                    params: {
                        class: classId
                    }
                })

            } else {

                this.$router.push({
                    name: Routes.TEACHER_CLASS_LIST.NAME,
                    params: {
                        class: classId,
                        course: courseId
                    }
                })

            }

        }
    }
}
</script>

<style scoped>

    .card-panel {
        cursor: pointer;
        font-size: 16px;
        color: #666;
        background: #fff;
    }

    .card-panel-item{
        background: whitesmoke;
    }

    .card-panel-description{
        padding-top: 5px;
        margin-top: 10px;
    }

    .card-panel-num {
        float: right;
        font-weight: bold;
        font-size: 21px;
    }

    .card-header, .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    ::v-deep(.class-card .el-card__header){
        background: var(--secondary-color);
        color: white;
    }

    ::v-deep(.el-divider--horizontal) {
        margin: 16px 0;
    }

    .class-card-icon{
        color: var(--primary-color);
    }

    .card-panel-text{
        font-size: 14px;
    }

</style>